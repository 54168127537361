.sliderwithsidebuttons__container{
    display: flex;
    flex-direction: column;
}

.sliderwithsidebuttons__title{
    display: flex;
    flex-direction: row;
    align-items: center;
}

#sliderwithsidebuttons__infobutton{
    margin-left: 3px;
    margin-top: 1px;
    width: 15px;
    height: 15px;
}

.sliderwithsidebuttons__body{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sliderwithsidebuttons__sidebutton{
    cursor: pointer;
    border-radius: 50%;
    margin-top: 3px;
    transition: 0.3s;
}

.sliderwithsidebuttons__sidebutton:hover{
    background-color: #af292e25;
}

.sliderwithsidebuttons__sidebutton:active{
    background-color: #af292e90;
}

#sliderwithsidebuttons__sidebutton__subtract{
    margin-right: 10px;
}

#sliderwithsidebuttons__sidebutton__add{
    margin-left: 10px;
}



