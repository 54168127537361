@media only screen and (max-width: 1200px) {
  .app__header__logo > span, img {
      display: none;
  }
  .app__header__logo {
    visibility: hidden;
  }
  .app__header__center__top{
    height: 15px;
  }
  .app__header__center__title{
    margin-bottom: 10px;
  }
}

*{
  margin: 0
}

.app{
  background: rgb(248,248,248);
  height: auto;
  display: flex;
  flex-direction: column;
}

.app__header{
  justify-content: center;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 5px 8px -9px rgba(0,0,0,0.75);
  display: flex;
  margin: 0 auto;
  width: 100%;
}

.app__header__logo{
  border-left: 2px #af292e solid;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.app__header__logo > img{
  width: 25px;
  padding-left: 8px;
  padding-bottom: 4px;
}

.app__header__logo > span{
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;
}

.app__header__center{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.app__header__center__title > span{
  font-size: 28px;
  font-weight: 500;
}

.app__header__right {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

a{
  text-decoration: none;
  font-size: 20px;
  display: inline-block;
  color: black;
  text-align: center;
  border-bottom: 2px solid transparent;
  width: 140px;
  margin-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

a:active{
  color: inherit;
  text-decoration: none;
}

a:hover{
  border-bottom: 2px #af292e solid;
}

ul{
  display:flex;
  list-style-type: none;
  padding: 0;
}

.navClicked{
  border-bottom: 2px #af292e solid;
  font-weight: 500;
}

.app__header__right{
  flex: 1;
}

.nav__yoursummary{
  display: flex;
  pointer-events: none;
}

.nav__yoursummary > a{
  color: grey;
}

.nav__yourchoice{
  display: flex;
  pointer-events: none;
}

.nav__yourchoice > a{
  color: grey;
}

.nav__aboutyou{
  display: flex;
  pointer-events: none;
}

.nav__aboutyou > a{
  color: grey;
}

.nav__yoursummary__lock{
  position: relative;
}

.nav__yourchoice__lock{
  position: relative;
}

.nav__aboutyou__lock{
  position: relative;
}
