.carouselpanel__container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carouselpanel__graphs__container{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.carouselpanel__title{
    display: flex;
    flex-direction: row;
}

#carouselpanel__title__infobutton{
    padding-left: 10px;
    width: 20px;
    height: 20px;
}

#carouselpanel__legend__infobutton{
    top: 370px;
    width: 20px;
    height: 20px;
}

