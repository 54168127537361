.composed__chart{
    overflow-y: hidden;
    overflow-x: hidden;
}

.yourchoice__graph__container{
    position: relative;
    width:80%;
    left: 10%;
    top: 2%;
    background-color: white;
    height: auto;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.masterpanel__container{
    width: 100%;
}

.slider__container{
    position: relative;
    margin-top: 50px;
    left: 25%;
}



.carousel__container{
    position: relative;
    width:90%;
    left: 5%;
    display: flex;
    flex-direction: column;
}

.panel__container{
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
}



#slider__edit__cpi{
    cursor: pointer;
    text-decoration: underline;
    color: #af292e;
}

.customTooltip{
    border:rgb(80,80,80) 1px solid;
    border-radius: 5px;
    padding: 5px;
    background: white;
}

h2{
    margin-top: 2%;
    text-align: center;
}

.option__box{
    position: relative;
    left: 30%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option__box > span{
    margin-top: 20px;
    font-size: 18px;
    cursor: pointer;
}

.option__box > span:hover{
    border-bottom: 2px solid #af292e;
}

.optionchoice__active{
    border-bottom: 2px solid #af292e;
}

#viewoptions__infobutton{
    position: absolute;
    width : 20px;
    height: 20px;
    left: 100%;
    top: 10px;
}

.charts__container{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#loading {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 2rem;
    height: 2rem;
    border: 5px solid #f3f3f3;
    border-top: 6px solid #af292e;
    border-radius: 100%;
    margin: auto;
    animation: spin 1s infinite linear;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.testerooni{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.radio__container__summary{
    position: relative;
    width: 100%;

    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column;

}