.faq__body{
    position: relative;
    width:80%;
    left: 10%;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 93vh;
}

.faq__container{
    position: relative;
    top: 5%;
    width: 60%;
    left: 20%;
}

