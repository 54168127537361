.aboutyou__body{
    position: relative;
    height: 200vh;
    width: 80%;
    left: 10%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

.stepper__topbar{
    position: absolute;
    top: 5%;
    left: 10%;
    width: 80%;
}

.radio__container{
    position: absolute;
    width: 40%;
    left: 30%;
    top : 12%;
    margin-top: 10px;
}

.calendar__container{
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    width:100%;
}


.input__salary{
    width: 100%;
}

.parttime__container{
    position: relative;
    display: flex;
    width: 120%;
}

.parttime__datepicker{
    margin-top: 20px;
    margin-left: 10px;
    width: 30%;
}

.parttime__proportion{
    width: 20%;
}

.parttime__addremove{
    cursor: pointer;
    margin-top: 30px;
    margin-left: 10px;
}

.addcircle__container{
    margin-top: 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 10px;
    width: 15%;
}

.container__rankAtRetirement{
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1200px) {

    .radio__container{
        width: 80%;
        left: 10%;
    }
}

#loading {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 20;
    width: 2rem;
    height: 2rem;
    border: 5px solid #f3f3f3;
    border-top: 6px solid #af292e;
    border-radius: 100%;
    margin: auto;
    animation: spin 1s infinite linear;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.parttime__validated{
    display:flex;
    align-items: center;
    align-content: center;
    margin-left: 13px;

}