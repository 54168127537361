.home__body{
    position: relative;
    width:80%;
    left: 10%;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    /* height: 110vh; */
}

.home__text__container{
    position: relative;
    margin-top: 60px;
    width: 60%;
    left : 20%;
    text-align: left;
    justify-content: center;
}

.home__text{
    margin-bottom: 20px;
    font-size: 16px;
}



.home__body a{
    outline: none;
    text-decoration: none;
    font-size: 16px;
    display: inline-block;
    color: #af292e;
    text-align: left;
    border-bottom: 2px solid transparent;
    width:auto;
    margin-bottom: auto;
    margin-left:auto;
    margin-right: auto;
}
  
.home__body a:active{
    color: inherit;
    text-decoration: none;
}
  
.home__body a:hover{
    border-bottom: 2px #af292e solid;
}

.home__body ul{
    display:block;
    list-style-type: circle;
    margin: 10px;
    padding-left: 10px;
  }

  .home__body ol{
    display:block;
    margin: 10px;
    padding-left: 10px;
  }

  .home__body li{
    margin: 5px;
    
  }  
